// components/LookUpItems.js
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Select, { GroupBase, MultiValue, OptionsOrGroups } from 'react-select';
import DynamicTable from './DynamicTable';
import { BRAND_NAMES } from './types/globals';
import useFetchItems from './hooks/useFetchItems';
import { getTodayDate } from './hooks/useRowUpdate';
import { useLookUpItems } from './hooks/useLookUpItems';
import { useUserContext } from './hooks/useUserContext';

// const options = BRAND_NAMES.map((brand) => ({ value: brand, label: brand }));
const allStylesOption = { value: 'all', label: 'All Styles' };
// const options = [allStylesOption, ...BRAND_NAMES.map((brand) => ({ value: brand, label: brand }))];
// Assuming BRAND_NAMES is correctly typed and options are set up correctly
const options: OptionsOrGroups<{ value: string; label: string; }, GroupBase<{ value: string; label: string; }>> = 
  [allStylesOption, ...BRAND_NAMES.map((brand) => ({ value: brand, label: brand }))];

const LookUpItems = () => {
  const {
    selectedStyles,
    setSelectedStyles,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    selectInputValue,
    setSelectInputValue,
    setItems, // fetch
  } = useLookUpItems();
  // Trigger for fetching items
  const [triggerFetch, setTriggerFetch] = useState(false);

  const { userMasterAccessRights } = useUserContext();


// Handling style change
const handleStyleChange = (newValue: MultiValue<{ value: string; label: string; }> | null) => {
  // Convert the readonly array to a new array and handle null
  const newSelectedStyles = newValue ? [...newValue] : [];
  setSelectedStyles(newSelectedStyles);

  // Assuming you want to keep selectInputValue in sync with selectedStyles
  setSelectInputValue(newSelectedStyles);
};

  // Clearing filters
  const clearFilters = () => {
    setSelectedStyles([]);
    setStartDate('');
    setEndDate(getTodayDate());
    setSelectInputValue([]);
  };

  // Applying filters triggers re-fetching
  const applyFilters = () => {
    setTriggerFetch(!triggerFetch);
  };

  const { items, loading, error } = useFetchItems({
    // style: selectedStyles.some(s => s.value === 'all') ? BRAND_NAMES.join(',') : selectedStyles.map(s => s.value).join(','),
    style: selectedStyles.some(s => s.value === 'all') ? BRAND_NAMES : selectedStyles.map(s => s.value),
    startDate,
    endDate,
  }, triggerFetch);


  // Reset trigger after fetching
  useEffect(() => {
    if (triggerFetch) {
      setTriggerFetch(false);
    }
  }, [triggerFetch]);

  const headers = ['Purchase Date', 'Style', 'Item Name', 'Size', 'Total Amount', 'Quantity', 'Price Per Item', 'Refund', 'Refund Date', 'Extra Charge', 'Category', 'Client', 'Cell Number', 'Notes', 'Gender'];
  // Include 'Cost' header only if user is admin
  if (userMasterAccessRights) {
    // headers.splice(5, 0, 'Cost');
    headers.push('Cost');

  }
    // Mapping function to align data with headers
    const mapDataToHeaders = (item: any) => {
      const data: any = {
        'Purchase Date': item.purchase_date,
        'Style': item.style,
        'Item Name': item.item_name,
        'Size': item.size,
        'Total Amount': item.amount,
        'Quantity': item.quantity,
        'Price Per Item': item.price,
        'Refund': item.refund,
        'Refund Date': item.refund_date,
        'Extra Charge': item.extra_charge,
        'Category': item.category,
        'Client': item.client,
        'Cell Number': item.customer_phone_number,
        'Notes': item.notes,
        'Gender': item.gender || ''
      };
  
      // Include 'Cost' property only if user is admin
      if (userMasterAccessRights) {
        data['Cost'] = item.cost;
      }
  
      return data;
    };

    const sortedItems = useMemo(() => {
      return [...items].sort((a, b) => {
        // For descending order; the more recent date should come first
        return b.purchase_date.localeCompare(a.purchase_date);
      });
    }, [items]);
  // Apply mapping to all items in your data array
  // const transformedData = items.map(mapDataToHeaders);
  const transformedData = sortedItems.map(mapDataToHeaders);


  const totalQuantityMedical = useMemo(() => {

    const sumTotalAmountMedical = items.reduce((acc, row) => {
        if (row.category === 'MEDICAL') {
            return acc + Number(row.quantity || 0);
        }
        return acc;
    }, 0);
    return (sumTotalAmountMedical);
// };
}, [items]);

const totalQuantityFootwear = useMemo(() => {

  const sumTotalAmountFootwear = items.reduce((acc, row) => {
      if (row.category !== 'MEDICAL') {
          return acc + Number(row.quantity || 0);
      }
      return acc;
  }, 0);
  return (sumTotalAmountFootwear);
// };
}, [items]);

const NetSales = useMemo(() => {
  const sumTotalAmount = items.reduce((acc, row) => {
      return acc + Number(row.amount || 0);
  }, 0);
  const sumTotalCost = items.reduce((acc, row) => (
      acc + (Number(row.cost || 0) * Number(row.quantity || 1))
    ), 0);

    const sumRefund = items.reduce((acc, row) => (
      acc + Number(row.refund || 0)
  ), 0);
  return (sumTotalAmount - sumTotalCost - sumRefund).toFixed(2);
  }, [items]);



  return (
  <div>
    <div className="filters flex flex-wrap justify-between items-center gap-4 mb-4">
      <Select
        isMulti
        options={options}
        value={selectInputValue}
        // className="basic-multi-select w-92" // Adjusted width
        className="  basic-multi-select w-full md:w-1/3"
        classNamePrefix="select"
        onChange={handleStyleChange}
        placeholder="Select Styles"
      />
      <div className="flex-grow flex items-center space-x-4 justify-center"> {/* Container for evenly spaced middle content */}
        <div className="flex flex-col">
          <label htmlFor="startDate" className="text-sm font-medium text-gray-700">Start Date</label>
          <input
            id="startDate"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="p-2 rounded"
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="endDate" className="text-sm font-medium text-gray-700">End Date</label>
          <input
            id="endDate"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="p-2 rounded"
            // max={new Date().toISOString().split('T')[0]}
            max={getTodayDate()}
          />
        </div>
      </div>
      <div className="flex"> {/* Container for buttons */}
        <button onClick={applyFilters} className="p-2 bg-blue-500 text-white rounded mr-8">
          Apply Filters
        </button>
        <button onClick={clearFilters} className="p-2 bg-red-500 text-white rounded">
          Clear Filters
        </button>
      </div>
    </div>

    <div className="mt-2 mb-2 p-2 bg-gray-50 rounded-lg shadow">
      <div className="text-lg font-semibold">Purchases</div>
      <div className="flex mt-2">
        <span className="text-md font-medium text-gray-700">Medical: </span>
        <span className="text-md font-medium text-blue-500">{totalQuantityMedical}</span>
      </div>
      <div className="flex mt-1">
        <span className="text-md font-medium text-gray-700">Footwear: </span>
        <span className="text-md font-medium text-green-500">{totalQuantityFootwear}</span>
      </div>
      {userMasterAccessRights && (
                <div className="flex mt-2"> {/* "Net Sales" span */}
                    <span className="text-md font-medium text-gray-700">Net Sales: </span>
                    <span className="text-md font-medium text-blue-500">{NetSales}</span>
                </div>
            )}
    </div>

    {loading ? (
      <p>Loading...</p>
    ) : error ? (
      <p>Error fetching items: {error.message}</p>
    ) : (
      <DynamicTable headers={headers} data={transformedData} />
    )}
  </div>
  );
};

export default LookUpItems;

