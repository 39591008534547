import api from './axios_api'; // Import your Axios instance

export const fetchCustomer = async (term: string) => {
  try {
    // console.log("getting fetchCustomer")
    const response = await api.get(`/get-customer/${term}`); // Replace '/endpoint' with your endpoint
    // console.log(response.data);
    return response.data
  } catch (error) {
    console.error('Error fetching data: ', error);
    return error
  }
};

export const fetchCustomerRefId = async (ref_id: string) => {
  try {
    // console.log("getting fetchCustomer")
    const response = await api.get(`/get-ref-id/${ref_id}`); // Replace '/endpoint' with your endpoint
    // console.log(response.data);
    return response.data
  } catch (error) {
    console.error('Error fetching data by ref id: ', error);
    return error
  }
};

export const fetchItemsByCategory = async (style: string) => {
  try {
    // console.log("getting")
    const response = await api.get(`/get-items/${style}`); // Replace '/endpoint' with your endpoint
    // console.log(response.data);
    return response.data
  } catch (error) {
    console.error('Error fetching data: ', error);
    return error
  }
};


//  DynamoDB
export const submitNewEnteredData = async (data: any) => {
  // console.log("NewData  To the Backend: ", data)
  try {
    const response = await api.post('submit-new-data', JSON.stringify(data)); // Send data in the body
    // console.log('Data submitted successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error submitting new data: ', error);
    return error;
  }
};

//  DynamoDB
export const submitEditedData = async (data: any) => {
  // console.log("Edited  To the Backend: ", data)
  try {
    const response = await api.post('submit-edited-data', JSON.stringify(data)); // Send data in the body
    // console.log('Data submitted successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error submitting edited data: ', error);
    return error;
  }
};


export const fetch_data_by_reference_id = async (id: string) => {
  try {
    // console.log("getting dynamo_db")
    const response = await api.get(`/get-aws-items/${id}`); // Replace '/endpoint' with your endpoint
    // console.log(response.data);
    return response.data
  } catch (error) {
    console.error('Error fetching data: ', error);
  }
};


export const fetch_data_by_style_and_date_range = async (queryParams: any) => {

  try {
    const response = await api.post(`/get-aws-analytics`, JSON.stringify(queryParams)); // Note the addition of '?' before `${queryParams}`
    return response.data
  } catch (error) {
    console.error('Error fetching data: ', error);
    return error
  }
};


// Temp Notes
export const submitNotes = async ({ reference_id, notes }: { reference_id: string, notes: string }) => {
  try {
    // console.log("AXIOS POST")
    const response = await api.post('/temp-notes', { reference_id, notes });
    return response.data;
  } catch (error) {
    // throw new Error(`Failed to submit notes: ${error.message}`);
    throw new Error(`Failed to submit notes: ${error}`);
  }
};

export const fetchNotes = async (reference_id: string) => {
  try {
    const response = await api.get(`/get-temp-notes/${reference_id}`);
    // console.log("GETCHING NOTES: RESPONSE",  response )
    // console.log("GETCHING NOTES:",  response.data[0] )
    // return response.data[0];
    return response;
    
  } catch (error) {
    throw new Error(`Failed to fetch notes: ${error}`);
  }
};


// /get-aws-analytics/<style_date>