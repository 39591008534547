import React, { useState } from 'react';
import BalanceSheet from './BalanceSheet';
import PurchaseHistory from './PurchaseHistory';
import LookUpItems from './LookUpItems';
// import History from './History'; // This component would need to be created by you

const TabView: React.FC = () => {
  const [activeTab, setActiveTab] = useState('balance_sheet');

  return (
    <div>
    <div className="border-b-2 border-gray-200">
      <ul className="flex">
        <li className="-mb-px">
          <button
            onClick={() => setActiveTab('balance_sheet')}
            className={`px-4 py-2 text-sm font-medium text-center transition-colors duration-300 ${
              activeTab === 'balance_sheet' ? "text-blue-600 border-b-2 border-blue-600" : "text-gray-600 hover:text-gray-800"
            } focus:outline-none`}
          >
            Balance Sheet
          </button>
        </li>
        <li className="-mb-px">
          <button
            onClick={() => setActiveTab('history')}
            className={`px-4 py-2 text-sm font-medium text-center transition-colors duration-300 ${
              activeTab === 'history' ? "text-blue-600 border-b-2 border-blue-600" : "text-gray-600 hover:text-gray-800"
            } focus:outline-none`}
          >
            History
          </button>
        </li>
        <li className="-mb-px">
          <button
            onClick={() => setActiveTab('look_up_items')}
            className={`px-4 py-2 text-sm font-medium text-center transition-colors duration-300 ${
              activeTab === 'look_up_items' ? "text-blue-600 border-b-2 border-blue-600" : "text-gray-600 hover:text-gray-800"
            } focus:outline-none`}
          >
            Search Items
          </button>
        </li>
      </ul>
    </div>
    <div className="pt-4">
      {activeTab === 'balance_sheet' && <BalanceSheet />}
      {activeTab === 'history' && <PurchaseHistory />}
      {activeTab === 'look_up_items' && <LookUpItems />}
    </div>
  </div>
  );
};

export default TabView;
