// constants.ts
export const BRAND_NAMES = [
    "SIGVARIS", 
    "RELAXSAN", 
    "OTC",
    "BAGS", 
    "ASICS", 
    "RIEKER", 
    "REMONTE", 
    "LACOSTE",
    "TIMBERLAND", 
    "EMU", 
    "MERRELL",
    "ALBERTO", 
    "BLUNDSTONE", 
    "ADIDAS", 
    "TEST MEDICAL",
    "TEST"
];
export {};
