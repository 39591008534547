import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL || `http://localhost:5000/`, // Ensure proper URL format
// baseURL: `http://localhost:5000/`, // Ensure proper URL format
headers: {
        'Content-Type': 'application/json'
        // Add other headers if needed
    }
});

export default api;
